import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import { OrganisationRole } from '@services/proficloud.interfaces'
import { Observable } from 'rxjs'
import { IMemberError, IMemberErrorID } from '../entities/member-error'
import { ICreateInvitationRequest, ICreateInvitationResponse, IInvitationDTO } from './DTO/IInvitationDTO'
import { IRemoveMemberError, OrganisationMembersResponse } from './DTO/member.dto'
import { RBACPolicyDTO, RBACPolicyRequest } from './DTO/rbac.dto'

@Injectable({
  providedIn: 'root',
})
export class MemberManagementHttpService extends HttpBaseService {
  /*
  A new, lean way to inject services, no constructor required.
   */
  private httpClient = inject(HttpClient)

  /*
  Returns a finite observable. All clients don't need to manually unsubscribe.
   */
  getMembersByOrganisation$(organisationId: string): Observable<OrganisationMembersResponse> {
    return new Observable<any>((sub) => {
      const url = this.backendUrls.iamUrl + `/organizations/${organisationId}/members`
      this.httpClient.get(url).subscribe({
        next: (usersOfOrganisation: OrganisationMembersResponse) => {
          sub.next(usersOfOrganisation)
          sub.complete()
        },
        error: (err: HttpErrorResponse) => {
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  getInvitationsByOrganisation$(organisationId: string): Observable<IInvitationDTO> {
    return new Observable<any>((sub) => {
      const url = this.backendUrls.iamUrl + `/organizations/${organisationId}/invitations`
      this.httpClient.get(url).subscribe({
        next: (invitations: IInvitationDTO) => {
          sub.next(invitations)
          sub.complete()
        },
        // In case of an error, catch it and log it. Return an empty list of invitations. This happens due to permission issues for all non-admins
        error: (err: HttpErrorResponse) => {
          console.error(err)
          sub.next([])
          sub.complete()
        },
      })
    })
  }

  updateMemberRole$(organisationId: string, memberId: string, role: string) {
    return new Observable<any>((sub) => {
      const url = this.backendUrls.iamUrl + '/organizations/' + organisationId + '/members/' + memberId
      this.httpClient.put(url, { userRole: role }).subscribe({
        next: () => {
          sub.next()
          sub.complete()
        },
        error: (err: HttpErrorResponse) => {
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  updateInvitationRole$(organisationId: string, invitationId: string, role: OrganisationRole) {
    return new Observable<any>((sub) => {
      const url = this.backendUrls.iamUrl + `/organizations/${organisationId}/invitations/${invitationId}`

      const params = {
        action: 'changerole',
        role: role,
      }

      this.httpClient.put(url, params).subscribe({
        next: () => {
          sub.next()
          sub.complete()
        },
        error: (err: HttpErrorResponse) => {
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  getRBACDevicePolicies$(organisationId: string): Observable<RBACPolicyDTO | null> {
    return new Observable<RBACPolicyDTO | null>((sub) => {
      const url = this.backendUrls.rbacServiceUrl + `/api/v1/policies/${organisationId}`
      this.httpClient.get(url).subscribe({
        next: (policies: RBACPolicyDTO) => {
          sub.next(policies)
          sub.complete()
        },
        // In case of an error, catch it and log it. Return null in this case. This happens due to permission issues for all non-admins
        error: (err: HttpErrorResponse) => {
          console.error(err)
          sub.next(null)
          sub.complete()
        },
      })
    })
  }

  setRBACDevicePolicies$(organizationId: string, policies: RBACPolicyRequest) {
    return new Observable<RBACPolicyDTO>((sub) => {
      const url = this.backendUrls.rbacServiceUrl + `/api/v1/policies/${organizationId}`
      this.httpClient.post(url, policies).subscribe({
        next: () => {
          sub.next()
          sub.complete()
        },
        error: (err: HttpErrorResponse) => {
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  inviteMemberToOrganisation$(email: string, message: string, role: string, organisationId: string): Observable<ICreateInvitationResponse> {
    return new Observable<ICreateInvitationResponse>((sub) => {
      const url = this.backendUrls.iamUrl + `/organizations/${organisationId}/invitations`

      const createInvitationRequest: ICreateInvitationRequest = {
        userEmail: email,
        message: message,
        userRole: role,
      }

      this.httpClient.post(url, createInvitationRequest).subscribe({
        next: (invitationResponse: ICreateInvitationResponse) => {
          sub.next(invitationResponse)
          sub.complete()
        },
        error: (inviteMemberError: HttpErrorResponse) => {
          let memberError: IMemberError

          if (inviteMemberError.error.error.id === 'iam.ORGANIZATION_USER_IS_ALREADY_MEMBER_ERROR') {
            memberError = {
              id: IMemberErrorID.ORGANIZATION_USER_IS_ALREADY_MEMBER_ERROR,
              message: inviteMemberError.message,
              category: 'INVITATIONS',
              externalID: inviteMemberError.error.error.id,
            }
          } else {
            memberError = {
              id: IMemberErrorID.INVITATION_HTTP_CALL_FAILED,
              message: inviteMemberError.message,
              category: 'INVITATIONS',
              externalID: inviteMemberError.error?.error?.id,
            }
          }
          sub.error(memberError)
          sub.complete()
        },
      })
    })
  }

  removeInvitationFromOrganisation$(invitationId: string, organisationId: string): Observable<any> {
    return new Observable<any>((sub) => {
      const url = this.backendUrls.iamUrl + `/organizations/${organisationId}/invitations/${invitationId}`
      this.httpClient.delete(url).subscribe({
        next: () => {
          sub.next()
          sub.complete()
        },
        error: (err) => {
          sub.error()
          sub.complete()
        },
      })
    })
  }

  removeMemberFromOrganisation$(userId: string, organisationId: string): Observable<any> {
    return new Observable<any>((sub) => {
      const url = this.backendUrls.iamUrl + `/organizations/${organisationId}/members/${userId}`
      this.httpClient.delete(url).subscribe({
        next: () => {
          sub.next()
          sub.complete()
        },
        error: (removeMemberError: IRemoveMemberError) => {
          let memberError: IMemberError

          if (removeMemberError.id === 'iam.ORGANIZATION_USER_IS_LAST_ADMIN_ERROR') {
            memberError = {
              id: IMemberErrorID.MEMBER_IS_LAST_ADMIN,
              message: removeMemberError.message,
              category: 'MEMBERS',
              externalID: removeMemberError.id,
            }
          } else {
            memberError = {
              id: IMemberErrorID.MEMBER_REMOVING_FAILED,
              message: removeMemberError.message,
              category: 'MEMBERS',
              externalID: removeMemberError.id,
            }
          }

          sub.error(memberError)
          sub.complete()
        },
      })
    })
  }
}
