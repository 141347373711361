export interface IMemberError {
  id: IMemberErrorID
  externalID?: string
  message: string
  category: 'MEMBERS' | 'INVITATIONS' | 'RBAC_DEVICES' | 'SESSION'
}

export enum IMemberErrorID {
  MEMBER_HTTP_CALL_FAILED,
  MEMBER_PARSING_FAILED,
  MEMBER_REMOVING_FAILED,
  MEMBER_IS_LAST_ADMIN,
  ORGANIZATION_USER_IS_ALREADY_MEMBER_ERROR,
  INVITATION_HTTP_CALL_FAILED,
  INVITATION_PARSING_FAILED,
  INVITATION_REMOVING_FAILED,
  INVITATION_EXISTS,
  RBAC_RETRIEVING_FAILED,
  RBAC_ASSIGNMENT_FAILED,
  SESSION_DATA_RETRIEVING_FAILED,
}
